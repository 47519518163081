import { IS_BROWSER } from '$fresh/runtime.ts';
import LTILocale, { BCP47Tag, getISOCodeLocale, getNativeLocale } from 'lti/LTILocale.ts';
import Button from 'components/button/Button.tsx';
import { useEffect, useRef, useState } from 'react-dom';
import IconChevronCompactDown from 'tabler-icons/chevron-compact-down.tsx';
import HydroButton from 'islands/button/HydroButton.tsx';
import RequestMethod from 'api/request/RequestMethod.ts';
import ExtendStrings from 'api/types/String.ts';

export default function LocaleSelector({ locale }: { locale: LTILocale }) {
    if (IS_BROWSER) {
        ExtendStrings();
    }

    const loc = Object.freeze(Object.fromEntries(
        Object.values(LTILocale).map((l) => [l, {
            flag: getISOCodeLocale(l).toLowerCase(),
            lang: getNativeLocale(l).capitalize(),
        }]),
    ));

    const maxLangLen = Math.max(...Object.values(loc).map((x) => x.lang.length));

    const localeContent = (l: LTILocale) => (
        <div className='w-28 h-7 flex flex-grow flex-shrink-0 flex-nowrap items-center justify-start text-left justify-items-start gap-2'>
            <span
                className={`fi fis fi-${loc[l].flag} !w-6 !h-6 !text-2xl !rounded-full !border-none !inset-0`}
            />

            <span className={`bg-transparent justify-start text-left w-[${maxLangLen}ch]`}>
                {loc[l].lang}
            </span>
        </div>
    );

    const locales = Object.entries(LTILocale).map(([k, l]) => [
        l,
        <HydroButton
            key={'SelectLocale_' + l}
            border={{ type: 'none', width: 0 }}
            color={{ bg: 'transparent', text: 'black', hover: 'gray-50' }}
            title={localeContent(l)}
            disabled={l === locale}
            static
            alt={k}
            onClick={() => {
                fetch('/api/locale', {
                    method: RequestMethod.POST,
                    body: l,
                }).then(() => {
                    window.location.reload();
                });
            }}
        />,
    ]);

    const ref = {
        button: useRef<HTMLDivElement>(null),
        dropdown: useRef<HTMLDivElement>(null),
    };

    const [dropdown, openDropdown] = useState(false);

    useEffect(() => {
        const close = () => openDropdown(false);

        addEventListener('scroll', close);
        addEventListener('resize', close);

        return () => {
            removeEventListener('scroll', close);
            removeEventListener('resize', close);
        };
    }, []);

    useEffect(() => {
        if (!(dropdown && ref.button.current && ref.dropdown.current)) {
            return;
        }

        const rect = ref.button.current.getBoundingClientRect();
        ref.dropdown.current.style.left = rect.left + 'px';
        ref.dropdown.current.style.top = (rect.top + 64) + 'px';
    }, [dropdown, ref.button.current, ref.dropdown.current]);

    return (
        <>
            <Button
                ref={ref.button}
                static
                color={{ hover: 'gray-50', bg: 'transparent', text: 'black' }}
                icon={
                    <div className='max-h-[48px] w-fit inline-flex gap-4 items-center justify-items-center'>
                        {localeContent(
                            Object.values(LTILocale).find((x) => x === locale)!,
                        )}

                        <IconChevronCompactDown className='w-4 h-4' />
                    </div>
                }
                shadow={true}
                alt='Select a language'
                onClick={() => openDropdown(!dropdown)}
            />

            {dropdown && (
                <div
                    ref={ref.dropdown}
                    className='fixed z-50 w-fit bg-white grid grid-cols-2 shadow-md border rounded-xl'
                >
                    {locales.map((x) => x[1])}
                </div>
            )}
        </>
    );
}
